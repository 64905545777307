<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentAddMember /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentAddMember from '@/components/team/dashboard/modules/users/member/ContentAddMember.vue'

export default 
{
  name: 'AddMember',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentAddMember
  }
}
</script>
<style>


</style>
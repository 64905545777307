<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4"> 
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Add New Member</h3> 
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <h6 class="card-title">
                Basic Info
              </h6>
              <div style="display:inline !important">
                <div class="d-flex align-items-center">
                  <div class="container">  
                    <form class="row g-3 justify-content-center align-items-center">
                      <div class="col-md-4" align="center">
                        <img src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-picture avatar img-circle img-thumbnail" alt="avatar">
                      </div>
                      <div class="col-md-8 align-items-center">
                        <label for="formFile" class="form-label">Upload a different photo...</label>
                        <input class="file-button form-control form-control-sm file-upload"  type="file" id="formFile">
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="validationDefault01" class="form-label">First name</label>
                        <input type="text" class="form-control" id="validationDefault01" value="Mark" required>
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="validationDefault02" class="form-label">Last name</label>
                        <input type="text" class="form-control" id="validationDefault02" value="Otto" required>
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="exampleInputEmail1" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                      </div>
                      <div class="col-md-6" align="left">
                        <label for="exampleInputPassword1" class="form-label">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1">
                      </div>
                      <div class="col-md-6" align="left">
                        <label for="exampleInputPassword2" class="form-label">Repeat Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword2">
                      </div>
                      <div class="col-md-6"  align="left">
                        <label for="validationDefault04" class="form-label">Time Zone</label>
                        <select class="form-select" id="validationDefault04" required>
                          <option selected disabled value="">Choose...</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div class="col-md-6"  align="left">
                        <label for="validationDefault05" class="form-label">Choose Organization</label>
                        <select class="form-select" id="validationDefault05" required>
                          <option selected disabled value="">Choose...</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="validationDefault06" class="form-label">Position</label>
                        <input type="text" class="form-control" id="validationDefault06">
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="validationDefault07" class="form-label">Type User</label>
                        <select class="form-select" id="validationDefault07" required>
                          <option selected disabled value="">Choose...</option>
                          <option>Project Manager</option>
                          <option>Project Viewer</option>
                          <option>Worker</option>
                        </select>
                      </div>
                      <div class="col-md-4" align="left">
                        <label for="validationDefault08" class="form-label">Modalitie</label>
                        <select class="form-select" id="validationDefault08" required>
                          <option selected disabled value="">Choose...</option>
                          <option>Remote</option>
                          <option>At office</option>
                          <option>Mix</option>
                        </select>
                      </div>
                      <div class="col-12" align="left">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required>
                          <label class="form-check-label" for="invalidCheck2">
                            Agree to terms and conditions
                          </label>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end m-1">
                        <button class="button-1 btn btn-primary" type="submit">Save</button>
                      </div>
                    </form>
                  </div>
                </div>                    
              </div>
            </div>                
          </div>
        </div>
      </div>
    </div>          
  </main>
</template>

<script>
  export default
  {
    name: 'ContentAddMember'
  }
</script>

<style></style>
